<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Directory Title Section -->
    <PageHeader class="pb-4" :heading="professor.firstName + ' ' + professor.lastName"/>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row justify="center" class="mt-0">
      <v-col class="contentMaxWidth">
        <v-row class="px-0 mb-4">
          <p class="text-h5 pr-4 mb-0">Current Courses</p>
          <DropMenu icon="mdi-calendar" :options="semesterFilters" @optionUpdate="filterCurrentTeachings(semesterFilters[$event].value)"/>
        </v-row>
        <v-row v-if="!currentlyTeaching.length" justify="center">
          <v-progress-circular indeterminate size="64" width="6" color="accent" class="py-12 my-12"/>
        </v-row>
        <v-row v-if="store.app.onDesktop && currentlyTeaching.length" class="px-0 pb-5">
          <v-col cols="3" class="pl-0 pr-4 py-0" :style="maxColHeight">
            <v-card v-for="(section, idx) in courseList" :key="idx" color="border" outlined width="100%"
                    class="mb-5 d-flex flex-column" style="border-radius: 10px">
              <v-card-title class="pt-3">
                <h5 class="font-weight-medium">{{ section._id.code }}</h5>
                <p class="text--secondary mb-0 text-body-2 ml-1"> • {{ section._id.campus }}</p>
                <v-spacer/>
                <v-chip small :color="semesterCSS(section._id.sem)[0]">
                  <v-icon size="18" :color="semesterCSS(section._id.sem)[0] + 'Text'">{{ semesterCSS(section._id.sem)[1] }}</v-icon>
                  <p :class="'mb-0 ml-1 font-weight-bold text-body-2 ' + semesterCSS(section._id.sem)[0] + 'Text--text'">
                    {{ section._id.year }}
                  </p>
                </v-chip>
              </v-card-title>
              <v-card-subtitle class="text--text text-body-2 pb-0">{{  section._id.name }}</v-card-subtitle>
              <v-card-actions>
                <v-btn text color="accent" class="text-capitalize" @click="$router.push({ path: '/courses', query: { c: section._id.code } })">
                  View Course
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="9" class="pl-2 pr-0 py-0">
            <WeeklyCalendar :events="events" :start-hour="calendarRange.start - 1" :end-hour="calendarRange.end">
              <template v-slot:event="{ event }">
                <v-container class="pa-1">
                  <p :class="'mb-0 font-weight-bold overflow-ellipsis ' + event.color + 'Text--text'">{{ event.name }}</p>
                  <p :class="'overflow-ellipsis ' + event.color + 'Text--text'">
                    {{ formatTime(event.start.split(' ')[1]) }} - {{ formatTime(event.end.split(' ')[1]) }}
                  </p>
                </v-container>
              </template>
            </WeeklyCalendar>
          </v-col>
        </v-row>
        <v-row v-else-if="!store.app.onMobile" class="px-0 mt-4 mb-0">
          <v-slide-group :show-arrows="!store.app.onMobile" mobile-breakpoint="600">
            <v-slide-item v-for="(section, idx) in courseList" :key="idx">
              <v-card color="border" class="my-4 mr-8 d-flex flex-column" outlined width="325px" style="border-radius: 10px">
                <v-card-title>{{  section._id.code }}
                  <p class="text--secondary mb-0 text-body-2 ml-1"> • {{ section._id.campus }}</p>
                  <v-spacer/>
                  <v-chip small :color="semesterCSS(section._id.sem)[0]">
                    <v-icon size="18" :color="semesterCSS(section._id.sem)[0] + 'Text'">{{ semesterCSS(section._id.sem)[1] }}</v-icon>
                    <p :class="'mb-0 ml-1 font-weight-bold text-body-2 ' + semesterCSS(section._id.sem)[0] + 'Text--text'">
                      {{ section._id.year }}
                    </p>
                  </v-chip>
                </v-card-title>
                <v-card-subtitle class="text--text">{{  section._id.name }}</v-card-subtitle>
                <v-spacer/>
                <v-card-text class="py-0 d-flex">
                  <v-icon class="mr-2" color="accent">mdi-calendar</v-icon>
                  <p class="mb-0">{{  section.sections.map(lec => { return lec.section }).join(' & ') }}</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn text color="accent" class="text-capitalize" @click="$router.push({ path: '/courses', query: { c: section._id.code } })">
                    View Course
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-row>
        <v-row v-else class="px-0 py-3">
          <v-card v-for="(section, idx) in courseList" :key="idx" color="border" outlined width="100%"
                  class="mb-5 d-flex flex-column" style="border-radius: 10px"
                  @click="$router.push({ path: '/courses', query: { c: section._id.code } })">
          <v-card-title>
            <h5 class="font-weight-medium">{{ section._id.code }}</h5>
            <p class="text--secondary mb-0 text-body-2 ml-1"> • {{ section._id.campus }}</p>
            <v-spacer/>
            <v-chip small :color="semesterCSS(section._id.sem)[0]">
              <v-icon size="18" :color="semesterCSS(section._id.sem)[0] + 'Text'">{{ semesterCSS(section._id.sem)[1] }}</v-icon>
              <p :class="'mb-0 ml-1 font-weight-bold text-body-2 ' + semesterCSS(section._id.sem)[0] + 'Text--text'">
                {{ section._id.year }}
              </p>
            </v-chip>
          </v-card-title>
          <v-card-subtitle class="text--text text-body-2">{{  section._id.name }}</v-card-subtitle>
          <v-spacer/>
          <v-card-text class="pt-0 d-flex">
            <v-icon class="mr-2" color="accent">mdi-calendar</v-icon>
            <p class="mb-0">{{  section.sections.map(lec => { return lec.section }).join(' & ') }}</p>
          </v-card-text>
        </v-card>
        </v-row>
        <v-row class="py-8 my-0"><v-divider/></v-row>
        <!-- COURSE HISTORY AREA - Course & Professor History Section -->
        <HistoryChart :history-data="historyData" type="professor" @updateSemester="getProfHistory($event)">
          <p class="text-h5 pr-4 mb-0">{{ store.app.onMobile ? 'History' : 'Professor History' }}</p>
        </HistoryChart>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import HistoryChart from '@/components/HistoryChart'
import DropMenu from '@/components/DropMenu'

import { timetableTimeToDateRange } from '@/utils/shared/timetableDateTime'
import { WEEK_START } from '@/utils/timetable/constants'
import PageHeader from '@/components/PageHeader'
import { formatTime } from '@/utils/shared/helpers'
import WeeklyCalendar from '@/components/shared/calendar/WeeklyCalendar.vue'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'ProfessorInfo',
  components: { WeeklyCalendar, PageHeader, HistoryChart, DropMenu },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    professor: { firstName: '', lastName: '', displayName: '' },
    currentlyTeaching: [],
    courseList: [],
    historyScreen: 0,
    historyData: {
      series: [{ name: '', data: [] }],
      sem: [],
      labels: [],
      details: []
    },
    events: [],
    semesterFilters: [
      { label: 'All Semesters', value: ['F', 'W', 'S'] }
    ],
    calendarRange: { start: 24, end: 1 },
    semMap: { F: 'Fall ', W: 'Winter ', S: 'Summer ' }
  }),
  beforeRouteEnter (to, from, next) {
    if (to.query.p) {
      next()
    } else {
      next(from.path)
    }
  },
  mounted () {
    this.getProfData(-1)
  },
  watch: {
    '$route.query': function () {
      this.getProfHistory(-1)
    }
  },
  computed: {
    maxColHeight () {
      return 'overflow: auto; max-height: ' + (((-(this.calendarRange.start - 8) + this.calendarRange.end - 5) * 48) + 5) + 'px;'
    }
  },
  methods: {
    debug () {
      debugger
    },
    formatTime,
    async getProfData (limit) {
      await this.getCurrentlyTeaching()
      await this.getProfHistory(limit)
    },
    async getCurrentlyTeaching () {
      const q = {
        query: 'query getCurrentlyTeaching($professor: String!) { getCurrentlyTeaching(professor: $professor) { _id, sections } }',
        variables: { professor: this.$route.query.p },
        operationName: 'getCurrentlyTeaching'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            if (graphQlRes.errors?.length > 0) {
              this.$router.push('/')
              this.$toast.warning('Sorry! We could not find a professor matching: ' + q.variables.professor)
            } else {
              this.currentlyTeaching = graphQlRes.data.getCurrentlyTeaching
              this.filterCurrentTeachings(null)
              // GTAG
              this.$gtag.event('prof_' + this.$route.query.p, { value: 1 })
            }
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async getProfHistory (limit) {
      // Need to call backend for course history data
      const q = {
        query: 'query getHistory($filter: JSON!, $limit: Float!) { getHistory(filter: $filter, limit: $limit) { history, meta } }',
        variables: {
          filter: { instructor: this.$route.query.p },
          limit: limit > -1 ? limit : 10
        },
        operationName: 'getHistory'
      }

      try {
        const response = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(q)
        })

        const graphQlRes = await response.json()

        if (graphQlRes.data) {
          this.historyData = graphQlRes.data.getHistory.history
          this.professor = graphQlRes.data.getHistory.meta
          this.store.data.setSelectedProf(this.professor)
          this.store.app.updateBreadcrumbs({ page: '/professors', text: this.professor.displayName })
        }
      } catch (error) {
        console.error('Failed to get prod history', error)
        this.$toast.error('An error occurred when contacting the server. Please try again later.')
      }
    },
    filterCurrentTeachings (semester) {
      this.events = []
      this.courseList = []
      this.currentlyTeaching.forEach((lecture) => {
        if (semester === null || semester.includes(lecture._id.sem)) {
          this.courseList.push(lecture)
          // Add new filter option if new semester
          const exists = this.semesterFilters.findIndex((filter) => { return filter.label[0] === lecture._id.sem })
          if (exists < 0) {
            this.semesterFilters.push({
              label: this.semMap[lecture._id.sem] + lecture._id.year,
              value: [lecture._id.sem]
            })
          }
          lecture.sections.forEach((section) => {
            section.time.forEach((time) => {
              const lecTime = timetableTimeToDateRange(time)
              const startTime = lecTime.start.toLocaleTimeString('en-US', { hour12: false })
              const endTime = lecTime.end.toLocaleTimeString('en-US', { hour12: false })
              const endTimeHour = parseInt(endTime.split(':')[0])
              const startTimeHour = parseInt(startTime.split(':')[0])
              if (endTimeHour > this.calendarRange.end) this.calendarRange.end = endTimeHour
              if (startTimeHour < this.calendarRange.start) this.calendarRange.start = startTimeHour

              const eventName = lecture._id.code + ' (' + section.section + ')'
              this.events.push({
                id: lecture._id.code,
                section: section.section,
                name: eventName,
                start: WEEK_START[time.day] + ' ' + startTime,
                end: WEEK_START[time.day] + ' ' + endTime,
                day: time.day,
                color: this.semesterCSS(lecture._id.sem)[0]
              })
            })
          })
        }
      })
    },
    semesterCSS (semester) {
      if (semester === 'F') {
        return ['orange', 'mdi-leaf']
      } else if (semester === 'W') {
        return ['blue', 'mdi-snowflake-variant']
      } else {
        return ['red', 'mdi-flower-tulip']
      }
    }
  }
}
</script>

<style scoped>

</style>
